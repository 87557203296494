import { disablePollutants, enablePollutants } from '@/api/aqpollutants';

const disablePollutant = {
	id: 'disablePollutant',
	selectionType: 'multiple',
	label: 'aqpollutants.actions.disablePollutant',
	functionality: 'UPDATE_AQPOLLUTANTS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqpollutants.actions.disablePollutant');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqpollutantsid: registries[i].aqpollutantsid
			});
		}

		const data = await disablePollutants(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enablePollutant = {
	id: 'enablePollutant',
	selectionType: 'multiple',
	label: 'aqpollutants.actions.enablePollutant',
	functionality: 'UPDATE_AQPOLLUTANTS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqpollutants.actions.enablePollutant');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqpollutantsid: registries[i].aqpollutantsid
			});
		}

		const data = await enablePollutants(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disablePollutant, enablePollutant]
};
